<template>
  <div>
    <!-- 교육별 대상자 목록 -->
    <c-table
      ref="table"
      title="교육별 대상자 목록"
      tableId="table"
      :columns="grid.columns"
      gridHeight="800px"
      :data="grid.data"
      :editable="false"
      :filtering="false"
      :checkClickFlag="false"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <!-- 대상자 연명부 -->
            <c-btn v-show="grid.data.length>0 && editable" label="대상자 연명부" icon="print" @btnClicked="btnPrint" />
            <!-- <c-btn label="검색" icon="search" @btnClicked="getList" /> -->
          </q-btn-group>
        </div>
      </template>
      
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'click'">
            <q-btn 
              round unelevated 
              size="10px"
              color="amber" 
              icon="search"
              @click.stop="() => { rowIndex = props.rowIndex, electronSignature = props.row.electronSignature}"
              >
              <q-popup-proxy :ref="'proxy' + props.rowIndex" :breakpoint="400">
                <component
                  :is="col.component"
                  :popupParam="props.row"
                  :rowIndex="props.rowIndex"
                  @callback="callback"
                />
              </q-popup-proxy>
            </q-btn>
          </template>
        </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "education-target-users-pop",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          plantCd: '',
          eduCourseId: '',
          startYmd: '',
          endYmd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "educationName",
            field: "educationName",
            // 교육명
            label: "교육명",
            align: "left",
            sortable: true,
            type: "link",
          },
          {
            name: "educationDate",
            field: "educationDate",
            // 교육기간
            label: "교육기간",
            style: 'width:180px',
            align: "center",
            sortable: true,
          },
          {
            name: 'attendeesDeptName',
            field: 'attendeesDeptName',
            style: 'width:140px',
            // 부서
            label: '부서',
            align: 'center',
            sortable: false,
          },
          {
            name: 'attendeesName',
            field: 'attendeesName',
            // 대상자
            label: '대상자',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'click',
            field: 'click',
            // 서명보기
            label: '서명보기',
            style: 'width:70px',
            type: 'custom',
            align: 'center',
            sortable: false,
            component: () => import(`${'@/pages/sop/edu/result/signaturePop.vue'}`)
          },
          {
            name: 'evaluationPoint',
            field: 'evaluationPoint',
            // 평가점수
            label: '평가점수',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'evaluationContent',
            field: 'evaluationContent',
            // 평가내용
            label: '평가내용',
            style: 'width:220px',
            align: 'center',
            sortable: false
          },
          {
            name: 'evaluationDate',
            field: 'evaluationDate',
            setHeader: true,
            // 이수일
            label: '이수일',
            style: 'width:110px',
            align: 'center',
            sortable: false
          },
          {
            name: 'noAttendanceReason',
            field: 'noAttendanceReason',
            // 불참사유
            label: '불참사유',
            style: 'width:200px',
            align: 'left',
            sortable: false
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        educationCourseName: '',
        eduCourseId: '',
      },
      period: [],
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      listUrl: "",
      printUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      
      if (this.popupParam.plantCd) {
        this.$set(this.searchParam, 'plantCd', this.popupParam.plantCd)
      }
      if (this.popupParam.eduCourseId) {
        this.$set(this.searchParam, 'eduCourseId', this.popupParam.eduCourseId)
      }
      if (this.popupParam.startYmd) {
        this.$set(this.searchParam, 'startYmd', this.popupParam.startYmd)
      }
      if (this.popupParam.endYmd) {
        this.$set(this.searchParam, 'endYmd', this.popupParam.endYmd)
      }
      // url setting
      this.listUrl = selectConfig.sop.edu.target.users.url;
      this.printUrl = selectConfig.sop.edu.result.print.courseByTargetUser.url;
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row) {
      this.popupOptions.title = "교육계획 상세"; // 교육계획 및 결과 상세
      this.popupOptions.param = {
        eduEducationId: row.eduEducationId ? row.eduEducationId : '',
        stepCd: row.documentStatusCd === 'ESC000001' ?  'ESC0000005' : 'ESC0000010',
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/sop/edu/result/educationResultDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    searchEduMaster() {
      this.popupOptions.title = '교육과정 검색'; // 교육과정 검색
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.searchParam.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/cc/educationCoursePop.vue'}`);
      this.popupOptions.width = '95%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEduCoursePopup;
    },
    closeEduCoursePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.selectData && data.selectData.length > 0) {
        this.searchParam.eduCourseId = data.selectData[0].eduCourseId
        this.searchParam.educationCourseName = data.selectData[0].educationCourseName
      }
    },
    removeEduMaster() {
      this.searchParam.eduCourseId = '';
      this.searchParam.educationCourseName = '';
    },
    btnPrint() {
      if (!this.searchParam.eduCourseId) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '검색조건에서 교육과정을 선택하세요.', // 검색조건에서 교육과정을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      } 
      let thisVue = this;
      this.$http.url = this.printUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request(
        _result => {
          let fileOrgNm = this.grid.data[0].educationCourseName + ' ' + this.$language('연명부') + '.docx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    callback(data, rowIndex) {
      let refName = 'proxy' + rowIndex;
      this.$refs[refName].hide();
    },
  },
};
</script>
